//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
// var bigInt = require("big-integer");
export default {
  name: "Instagram",

  data() {
    return {
      instaMedia: Array.from(Array(4).keys()),
      demoData: [
        {
          media_url: "images/pink-slipper.jpg",
          permalink: "https://www.instagram.com/walkwayshoesin/",
        },
        {
          media_url: "images/blue-flotter.jpg",
          permalink: "https://www.instagram.com/walkwayshoesin/",
        },
        {
          media_url: "images/brown-flotter.jpg",
          permalink: "https://www.instagram.com/walkwayshoesin/",
        },
        {
          media_url: "images/white-slipper.jpg",
          permalink: "https://www.instagram.com/walkwayshoesin/",
        },
      ],
    };
  },
  methods: {
    async getInstaData() {
      try{
        const response  = await axios.get(`${this.$store.state.list.api_url}/pim/instagram.php`)
          if(response){

            let images = []; 
            response.data.result.forEach((element) => {
              if (images.length === 6) {
                return;
              }
              if (element.media_type === "IMAGE") {
                images.push(element);
              }
            });

            this.instaMedia = images;
        }
      }catch(err) {
          this.instaMedia = this.demoData;
        }
    },
  },
  async fetch() {
    await  this.getInstaData();
  },
};
