//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";

export default {
  head() {
    return {
      bodyAttrs: {
        class: "w-store home-page",
      },
      title: this.meta_title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.meta_description,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.meta_title,
        },
        {
          hid: "og:url",
          property: "og:url",
          content: this.$store.state.list.base_Url + this.$route.path,
        },
        {
          hid: "og:image",
          property: "og:image",
          content: "https://walkwayshoes.com/images-walk/logo.png",
        },
        {
          hid: "og:image-width",
          property: "og:image-width",
          content: "200",
        },
        { hid: "og:image-height", content: "200" },
        {
          hid: "og:description",
          property: "og:description",
          content: this.meta_description,
        },
        {
          name: "twitter:site",
          content: "@Walkway",
        },
        {
          name: "twitter:title",
          content: this.meta_title,
        },
        {
          name: "twitter:description",
          content: this.meta_description,
        },
        {
          name: "twitter:image",
          content: "https://walkwayshoes.com/images-walk/logo.png",
        },
        {
          name: "twitter:app:country",
          content: "IN",
        },
        {
          name: "twitter:app:name:iphone",
          content: "Walkway",
        },
        {
          name: "twitter:app:url:iphone",
          content: this.$store.state.list.base_Url + this.$route.path,
        },
        {
          name: "twitter:app:name:googleplay",
          content: "Walkway",
        },
        {
          name: "twitter:app:url:googleplay",
          content: this.$store.state.list.base_Url + this.$route.path,
        },
        {
          name: "twitter:app:id:googleplay",
          content: "com.walkway.android",
        },
        { hid: "keywords", property: "keywords", content: this.meta_keyword },
      ],
      script: [
        {
          json: [
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "WALKWAY",
            "url": "https://walkwayshoes.com/",
            "logo": "https://walkwayshoes.com/images-walk/logo.png",
            "foundingDate": "2009",
            "description": "Walkway shoes",
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "Cutomer Support",
              "areaServed": "IN",
              "telephone": "+91-7977809990",
              "email": "customercare@walkwayshoes.com"
            },
            "sameAs": [
              "https://www.instagram.com/walkwayshoesin/",
              "https://www.facebook.com/walkwayshoesin/",
              "https://www.youtube.com/channel/UCZzbIGDUQsdu63omDK_GYaQ",
              "https://twitter.com/walkwayshoes"
            ],
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "401, Kanakia Zillion,4th Floor",
              "addressLocality": "LBS Marg & CST Road Junction",
              "addressRegion": "Kurla(West), Mumbai, Maharashtra",
              "postalCode": "400070",
              "addressCountry": "India"
            }
          },
          ],
          type: "application/ld+json",
        },
      ],
    };
  },

  name: "Home",
  computed: {
    mobileMainBanners() {
      let mobileBanners = this.banners.filter((element) => {
        if (element.title == "mainBanner" && element.device == "mobile") {
          return element;
        }
      });
      return mobileBanners;
    },
    desktopMainBanners() {
      let desktopBanners = this.banners.filter((item) => {
        if (item.title == "mainBanner" && item.device == "desktop") return item;
      });
      return desktopBanners;
    },
  },
  data() {
    return {
      scrollSlider: [],
      saleBanner: {
        url: "sale",
        image: "images-walk/limited-time-banner.png",
        bg_color: "#35bdd7",
      },
      wishlist: [],
      activeTab: "men",
      isMobile: false,
      banners: [],
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      error: "",

      slickOptions: {
        slidesToShow: 1,
        // autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: false,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2500,
        infinite: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: true,
              dots: true,
              autoplaySpeed: 3000,
              pauseOnDotsHover: true,
              //  fade: true,
              // cssEase: "linear",
            },
          },
        ],
        // Any other options that can be got from plugin documentation
      },

      slickOptionstwoOld: {
        slidesToShow: 1,
        // autoplay: true,
        autoplaySpeed: 2000,
        pauseOnFocus: false,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        // arrows: true,
        dots: false,
        fade: true,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true,
              autoplaySpeed: 1000,
              pauseOnDotsHover: true,
            },
          },
        ],
        // Any other options that can be got from plugin documentation
      },
      slickOptionstwo: {
        dots: true,
        infinite: true,
        speed: 2000,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      slickOptionsSlider: {
        slidesToShow: 4,
        responsive: [
          {
            breakpoint: 997,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: "50px",
            },
          },
          {
            breakpoint: 767,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              centerMode: false,
              // centerPadding: '50px'
            },
          },
        ],
      },
      slickOptionLovingBrands: {
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
        centerPadding: "65px",
        responsive: [
          {
            breakpoint: 992,
            settings: {
              dots: false,
              slidesToShow: 3,
              slidesToScroll: 1,
              centerMode: false,
              centerPadding: "65px",
              arrows: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              dots: true,
              arrows: true,
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: true,
              // centerMode: true,
              // centerPadding: '50px'
            },
          },
        ],
        // Any other options that can be got from plugin documentation
      },
      seoContentData: ''
    };
  },

  async fetch() {
    await this.getBanner();
    // this.$store.state.list.footerDescription = ``;
    await axios
      .get(
        this.$store.state.list.api_url +
          `/pim/pimresponse.php/?service=cms_page&url_key=home&store=1`
      )
      .then((response) => {
        // this.content = response.data.result[0].content;
        this.meta_title = response.data.result[0].meta_title;
        this.meta_description = response.data.result[0].meta_description;
        this.meta_keyword = "";
      });
    //         this.scrollSlidePro ()

    this.$store.state.list.plpSeoContent = '';
    this.seoContentData = `
         <div class="home-page seo-content">
        <h1><strong>Enter the Walkway Experience : Your One-Stop Destination for Affordable Trendy Footwear&nbsp;</strong></h1>
        <p>
          <span>Are you looking to indulge in some </span>online shoe shopping
          <span>? Choose a brand that has mastered the blend of quality, comfort, and style in 
          its footwear collection. Presenting to you &ndash; Walkway, your one-stop destination 
          for the trendiest </span>shoes online.
        </p>
        <p>
          <span>At Walkway, we believe that style should be accessible to everyone. This is why we offer
           you and your family a wide range of fashionable footwear at affordable prices. Be it a dinner
           date, a professional commitment, or a casual day out with the family, head to Walkway's
           </span>online shoe-shopping <span>website and select a pair that best fits your
           needs and preferences.</span>
        </p>

        <h2><strong>Why Choose Walkway for Your Online Shoe Purchase?</strong></h2>
        <p>
          <span>When you choose Walkway to </span>buy shoes online in India
          <span>, you select a brand for more than style and comfort. Walkway's attractive range of shoes
          represents its mission of offering high-quality, versatile shoes at an affordable price.</span>
        </p>
        <p>
          <span>Here's why Walkway should be your go-to destination for </span>online shoe shopping
          <span>:&nbsp;</span>
        </p>
        <ul>
        <li>
          <strong>Ergonomic Design:</strong><span> Each Walkway shoe is created with the foot's anatomy in mind,
          ensuring a snug, comfortable fit that supports your natural stride.</span>
        </li>
        <li>
          <strong>Breathable Materials:</strong><span> Say goodbye to discomfort with our breathable materials that
          keep your feet fresh all day.</span>
        </li>
        <li>
          <strong>Cushioning for All-Day Wear:</strong><span> Our cushioned insoles are designed to absorb impact
          and relieve pressure points, making every step a pleasure.</span>
        </li>
        <li>
          <strong>Durable Construction:</strong><span> When you </span>buy shoes online
          <span>, there is always a lingering doubt about durability. With Walkway's quality assurance,
          you will receive a pair designed to stand the test of time and wear.</span>
        </li>
        <li>
          <strong>Versatility in Style:</strong><span> Gone are the days when choices were limited. Walkway's
          </span>online shoe purchase<span> website brings you a versatile footwear range
          fit for every season and occasion. So whether it is summer or winter, whether you are headed to a
          party or running errands, we have just the right footwear to complement your style.</span>
        </li>
        </ul>
  
        <h2><strong>Shoes&rsquo; Online Shopping in India Made Simple</strong></h2>
        <p>
          <span>Walkway is your everyday fashion brand for your entire family. Not only do we focus on
          the comfort of your feet, but we also guarantee a comfortable shopping experience when you </span>
          buy shoes online<span> with us. Our website promises a streamlined and convenient 
          </span>online shoe-shopping <span>experience. Now, </span>shoes&rsquo;
          online shopping in India <span>is easier than ever with our user-friendly website. To 
          make your </span>online shoe purchases<span style="font-weight: 400;">, all you 
          need to do is follow these simple steps:</span>
        </p>
        <ul>
        <li><span>Visit Walkway's official website to </span>buy shoes online</li>
        <li><span>Select from the Men's, women's, or kids&rsquo; category</span></li>
        <li><span>Choose from the shoe type, brand, or occasion for your </span>online shoe purchase</li>
        <li><span>Use the filter feature to focus on the type of footwear you desire</span></li>
        <li><span>Add your selected footwear to the cart and then check out</span></li>
        <li><span>Fill out your delivery address and make your payment</span></li>
        </ul>
        <p>
          <span>And voila! Your </span>online shoe purchase
          <span> is successful! Your chosen pair or pairs will be delivered to your address within a few days.
          It's that simple!</span>
        </p>
        <h2>
          <strong>Buy Shoes Online With Walkway</strong>
        </h2>
        <p>
          <span>Make Walkway your first choice to </span>buy shoes online
          <span>. Join the countless satisfied customers who have found the perfect pair for
          their family members with Walkway's </span>online shoe purchase
        </p>
        <p>
          <span>Visit our website today and choose from hundreds of affordable, stylish, and comfortable footwear.
          Walkway has something in store for everyone, whether it is kids&rsquo; lace-ups, men's sneakers, or 
          women's boots. Step into the new world of </span>online shoe purchases
          <span> today!</span>
        </p>

        <h2><strong>Walkway: Continuing the Legacy of Metro</strong></h2>
        <p>
          <span>Founded in 2009, Walkway has emerged as a top brand, providing affordable and modish footwear for
            the entire family. With our robust e-commerce website, we strive to make </span>online 
            shoe purchases  seamless and hassle-free for our customers.
        </p>
        <p>
          <span>On top of continuing Metro's legacy of delivering top-notch quality, we have added our vision of
          affordability to offer you a product that provides value for your money. So, what are you waiting for? 
          Visit our website and </span>buy shoes online today!
        </p>
        <p>
          <span>Head to Walkway and start your journey to </span>buy shoes online in India
          <span>. Step out in style with Walkway shoes - where every step is a statement of elegance, comfort, and affordability.
          </span>
        </p>
        </div>
    `
  },
  beforeMount() {
    if (window.innerWidth < 767) {
      this.isMobile = true;
    }

    // this.scrollSlidePro();
  },
  methods: {
    async getBanner() {
      try {
        const response = await axios.get(
          this.$store.state.list.api_url +
            "/pim/pimresponse.php/?service=banner_slider&store=1"
        );
        if (response) {
          this.banners = response.data.result;
          this.saleBanner = this.banners.find((x) => x.title === "sale-banner");
        }
      } catch (err) {}
    },
    reInitHandler() {
      this.$nextTick(() => {
        this.refs.slick.reSlick();
        this.refs.slicktwo.reSlick();
      });
    },
    scrollSlidePro() {
      axios
        .get(
          this.$store.state.list.cart_api_url + `/product/product-slider-list`
        )
        .then((response) => {
          if (response.data.success === true)
            this.scrollSlider = response.data.data;
        });
    },
    truncate(value) {
      if (value.length >= 50) {
        return value.substring(0, 50) + "...";
      } else {
        return value;
      }
    },
    wishActive: function (wish) {
      return this.wishlist.indexOf(wish) > -1;
    },
    add_w(id) {
      if (
        this.$store.state.list.customer_id != "" &&
        this.$store.state.list.customer_id != null
      ) {
        this.$store.state.list.page_loader = true;
        var form = new FormData();
        form.append("product_id", id);
        form.append("customer_id", this.$store.state.list.customer_id);
        form.append(
          "customer_session",
          this.$store.state.list.customer_session
        );
        axios
          .post(
            this.$store.state.list.cart_api_url + `/wishlist/add-wishlist`,
            form
          )
          .then((response) => {
            this.$store.state.list.page_loader = false;
            if (response.data.success == true) {
              //this.$store.state.list.wishlist = response.data.data.product;
              this.wishlist = response.data.data.product.split(",");
              this.$store.state.list.wishlist_count =
                response.data.data.total_row;
              //this.wishlist = true;
              this.$toast.success(response.data.message, {
                position: "top-right",
                duration: 4000,
              });
            } else {
              this.$store.state.list.page_loader = false;
              if (response.data.data.customer_session_status == false) {
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_customer",
                  "",
                  "1y"
                );
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_cus_sess",
                  "",
                  "1y"
                );
                this.$store.state.list.customer_id = "";
                this.$store.state.list.customer_session = "";
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_cart",
                  "",
                  "1y"
                );
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_ct_sess",
                  "",
                  "1y"
                );
                this.$store.state.list.cart_id = "";
                this.$store.state.list.cart_session = "";
                this.$store.state.list.cart_product = [];
              }
            }
          });
      } else {
        this.$store.state.list.login_popup = true;
      }
    },
    remove_w(id) {
      this.$store.state.list.page_loader = true;
      var form = new FormData();
      form.append("product_id", id);
      form.append("customer_id", this.$store.state.list.customer_id);
      form.append("customer_session", this.$store.state.list.customer_session);
      axios
        .post(
          this.$store.state.list.cart_api_url + `/wishlist/remove-wishlist`,
          form
        )
        .then((response) => {
          if (response.data.success == true) {
            this.$store.state.list.page_loader = false;
            if (response.data.data.product) {
              this.wishlist = response.data.data.product.split(",");
              this.$store.state.list.wishlist_count =
                response.data.data.total_row;
            } else {
              this.$store.state.list.wishlist_count = "0";
              this.wishlist = [];
            }
            this.$toast.success(response.data.message, {
              position: "top-right",
              duration: 4000,
            });
          } else {
            this.$store.state.list.page_loader = false;
            if (response.data.data.customer_session_status == false) {
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_customer",
                "",
                "1y"
              );
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_cus_sess",
                "",
                "1y"
              );
              this.$store.state.list.customer_id = "";
              this.$store.state.list.customer_session = "";
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_cart",
                "",
                "1y"
              );
              $cookies.set(
                window.location.hostname.substring(10, 4) + "_ct_sess",
                "",
                "1y"
              );
              this.$store.state.list.cart_id = "";
              this.$store.state.list.cart_session = "";
              this.$store.state.list.cart_product = [];
            }
          }
        });
    },
    get_wish() {
      var form = new FormData();
      form.append("customer_id", this.$store.state.list.customer_id);
      form.append("customer_session", this.$store.state.list.customer_session);
      axios
        .post(
          this.$store.state.list.cart_api_url + `/wishlist/customer-wishlist`,
          form
        )
        .then((response) => {
          if (response.data.success == true) {
            //this.$store.state.list.wishpro=response.data.data.product.split(',');
            this.wishlist = response.data.data.product.split(",");
            this.$store.state.list.wishlist_count =
              response.data.data.total_row;
          }
        });
    },
    gtm_product_click: function (name, id, price, category, position, list) {
      this.$gtm.push({
        event: "productClick",
        action: "productClick",
        label: list,
        ecommerce: {
          click: {
            actionField: {
              list: list,
              action: "click",
            },
            product: [
              {
                name: name,
                id: id,
                price: price,
                category: category,
                position: position + 1,
              },
            ],
          },
        },
      });
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
  },
  mounted() {
    console.log("banners", this.banners);
    window.scrollTo(0, 0);

    this.$store.state.list.error_message = "";
    if (
      this.$store.state.list.customer_id != "" &&
      this.$store.state.list.customer_id != null
    ) {
      this.get_wish();
    }
  },
};
