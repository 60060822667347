//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "LazyImage",
  data() {
    return {
      showOriginal: false,
    };
  },
  props:{
    alt:{
      type: String,
      required: false,
      default: "",
    },
    src: {
      type: String,
      required: false,
      default: "#",
    },
    index: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  mounted() {
    setTimeout(()=>{
      this.showOriginal = true;
    },2000)
  },
  methods: {
    imageLoaded() {
      this.showOriginal = true;
    },
  },
};
